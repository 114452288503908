<template>
  <WidgetTableColumnFilterWrapper
    type="text"
    :filter-value="inputData"
    @resetFilter="resetFilter">
    <template #input>
      <el-input
        v-model="inputData"
        :placeholder="$t(currentRadio.title)"
        text
        clearable
        @input="applyFilter" />
    </template>
    <template #main>
      <template
        v-for="({title, filterKey}, key) in radioList"
        :key="key">
        <el-radio
          v-model="radio"
          class="full-width"
          :label="filterKey">
          {{ $t(title) }}
        </el-radio>
        <el-divider />
      </template>
    </template>
  </WidgetTableColumnFilterWrapper>
</template>

<script setup>
import { computed, ref } from 'vue'
import WidgetTableColumnFilterWrapper from './WidgetTableColumnFilterWrapper'
const emit = defineEmits(['applyFilter'])
const radioList = [{
  title: 'Начинается с',
  filterKey: 'from'
}, {
  title: 'Равно',
  filterKey: 'eq'
}, {
  title: 'Содержит',
  filterKey: 'contains'
}]
const radio = ref('contains')
const inputData = ref('')
const applyFilter = (val) => {
  emit('applyFilter', {
    [radio.value]: val,
  })
}
const resetFilter = () => {
  inputData.value = ''
  radio.value = 'contains'
  emit('applyFilter', {
    [radio.value]: '',
  })
}
const currentRadio = computed(() => {
  return radioList.find(({filterKey}) => filterKey === radio.value) || {}
})
</script>

<style lang="scss" scoped>
  
</style>
